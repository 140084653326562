<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Start Sequence</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleStartSequence" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Sequence</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.sequences }">
                                    <Field autocomplete="off" name="sequences" v-model="form.sequence_id" rules="required">
                                        <multiselect
                                            v-model="form.sequence_id"
                                            label="name"
                                            value-prop="id"
                                            :searchable="true"
                                            :options="sequences"
                                            placeholder="Select Sequence"
                                            mode="tags"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="sequences" class="text-danger" />
                            </div>
                        </div>
                        <div v-show="form.sequence_id.length == 1">
                            <label for="msg-start" class="switch_option capsule_btn">
                                <h5>Start at message 1</h5>
                                <input type="checkbox" id="msg-start" v-model="form.msg_start_one" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <div class="form_grp" v-show="!form.msg_start_one">
                                <div class="group_item">
                                    <div class="field_wpr">
                                        <multiselect
                                            v-model="form.selected_email_order"
                                            label="name"
                                            value-prop="order"
                                            :searchable="true"
                                            :options="orders"
                                            placeholder="Published Message 1"
                                        >
                                      </multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="form.sequence_id.length">
                            <label for="sms_throttle" class="switch_option capsule_btn">
                                <h5>Throttle for contact timezone</h5>
                                <input type="checkbox" id="sms_throttle" v-model="form.sms_throttle_tz" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <schedule-component :schedule-data="form" title="sequence" :close-modal="closeModal" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Starting' : 'Start' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate';
    import { mapState, mapActions } from 'vuex';

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    export default {
        name: 'StartSequence',

        data () {
            return {
                form: {
                    contacts: '',
                    sequence_id: [],
                    action: '/contacts/add-sequence',
                    type: 'leads',
                    msg_start_one: 1,
                    selected_email_order: 0,
                    sms_throttle_tz: 1,
                },
                orders: [],
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            'form.sequence_id' (ids) {
                const vm = this;

                if (ids.length == 1) {
                    vm.resetOrders(ids[0]);
                }
            },

            modelValue (value) {
                const vm = this;

                if (value == true) {
                    vm.form.contacts = vm.contacts;
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                if (vm.sequences.length == 0) {
                    vm.getAllSequences();
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            ScheduleComponent
        },

        computed: {
            ...mapState({
                sequences: state => state.sequenceModule.allSequences,
                bulkActionLoader: state => state.contactModule.bulkActionLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getAllSequences: 'sequenceModule/getAllSequences',
                getEmailCount: 'sequenceModule/getEmailCount',
                startSequence: 'sequenceModule/startSequence',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    contacts: '',
                    sequence_id: [],
                    action: '/contacts/add-sequence',
                    type: 'leads',
                    msg_start_one: 1,
                    selected_email_order: 0,
                    sms_throttle_tz: 1,
                };
            },

            resetOrders (sequenceId) {
                const vm = this;
                const sequence = vm.sequences.find(sequence => sequence.id == sequenceId);

                vm.orders = [];

                for (let i = 0; i < sequence.publish_messages; i++) {
                    vm.orders.push({ name: `Published Message ${(1 + i)}`, order : i });
                }
            },

            handleStartSequence (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                vm.startSequence(vm.form);
            },
        },
    }
</script>
